import {Action, Module, VuexModule} from "vuex-module-decorators";
import UserApi from "@/api/UserApi";

@Module
class UserModule extends VuexModule {
    @Action
    getListUser(params) {
        return UserApi.list(params);
    }

    @Action
    detailUser(payload) {
        return UserApi.detail(payload);
    }

    @Action
    updateUser(payload) {
        const id = payload.id;
        const params = payload.data;
        return UserApi.update(id, params);
    }
}

export { UserModule }