<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">List Thread</h1>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <section class="content">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <form @submit.prevent="submitForm">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-4">
                  <label class="col-form-label">Search</label>
                  <input class="form-control form-control-sm" v-model="filter.title">
                </div>
<!--                <div class="form-group col-4">-->
<!--                  <label class="col-form-label" for="categories">Category</label>-->
<!--                  <select class="form-control" id="categories" v-model="filter.category_id">-->
<!--                    <option v-for="(category, index) in categories"-->
<!--                      :value="category.id"-->
<!--                      :key="index">-->
<!--                      {{ `${category.name} - ${category.nameEn}` }}-->
<!--                    </option>-->
<!--                  </select>-->
<!--                </div>-->
<!--                <div class="form-group col-2">-->
<!--                  <label class="col-form-label">Hide</label>-->
<!--                  <div class="custom-control custom-switch">-->
<!--                    <input type="checkbox" class="custom-control-input" id="isHide"-->
<!--                       true-value="1"-->
<!--                       false-value="0"-->
<!--                       v-model="filter.is_hide">-->
<!--                    <label class="custom-control-label" for="isHide"></label>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="form-group col-2">-->
<!--                  <label class="col-form-label">Pinned</label>-->
<!--                  <div class="custom-control custom-switch">-->
<!--                    <input type="checkbox" class="custom-control-input" id="isPinned"-->
<!--                       true-value="1"-->
<!--                       false-value="0"-->
<!--                       v-model="filter.is_pinned">-->
<!--                    <label class="custom-control-label" for="isPinned"></label>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
              <div class="row">
                <div class="form-group col-4">
                  <button type="submit" class="btn btn-sm btn-primary mr-2"
                      @click="submitForm">Search</button>
                  <button type="button" class="btn btn-sm btn-default"
                      @click="clearForm">Clear</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container-fluid card-body card">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Total record: {{ total }}</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <table id="example1" class="table table-bordered table-striped">
                <thead>
                <tr class="text-center">
                  <th>No</th>
                  <th>ID</th>
                  <th>Category</th>
                  <th>Title</th>
                  <th>Status Badge Code</th>
                  <th>Is Pinned</th>
                  <th>Is Hide</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr class="text-center" v-for="(thread, i) in threads" :key="i">
                  <td>{{ (page - 1) * pageSize + i + 1 }}</td>
                  <td>{{ thread.id }}</td>
                  <td>{{ thread?.category?.name || "" }}</td>
                  <td>{{ thread.title }}</td>
                  <td>
                    <span class="badge badge-primary" v-if="thread.statusBadgeCode == StatusBadgeCodeEnum.ACTIVE">
                      Active
                    </span>
                    <span class="badge badge-secondary" v-else>Reject</span>
                  </td>
                  <td>
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" :id="'isPinned-' + thread.id"
                        true-value="1"
                        false-value="0"
                        :checked="thread.isPinned"
                        @change="togglePinThread(thread)">
                      <label class="custom-control-label" :for="'isPinned-' + thread.id"></label>
                    </div>
                  </td>
                  <td>
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" :id="'isHide-' + thread.id"
                        true-value="1"
                        false-value="0"
                        :checked="thread.isHide"
                        @change="toggleHideThread(thread)">
                      <label class="custom-control-label" :for="'isHide-' + thread.id"></label>
                    </div>
                  </td>
                  <td>{{ dateFromTimestamp(thread.createdAt) }}</td>
                  <td>
                    <router-link :to="{
                      name: 'thread.edit',
                      params: {id: thread.id}
                    }">
                      <a title="Edit" class="btn btn-default btn-xs mr-2"><i class="fa fa-edit"></i></a>
                    </router-link>
                    <a title="Delete" @click="actionDelete(thread.id)" class="btn btn-danger btn-xs mr-2">
                      <i class="fa fa-trash"></i>
                    </a>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                </tfoot>
              </table>
              <div class="text-right">
                <pagination class="text-center" v-model="page" :records="total" :per-page="pageSize" @paginate="changePage"/>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </section>
</template>

<script>
import {nextTick, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnum";
import {Status} from "@/enums/BaseEnum";
import {useRoute, useRouter} from "vue-router";
import Pagination from 'v-pagination-3';
import {dateFromTimestamp} from "@/helpers/date";
import {StatusBadgeCodeEnum} from "@/enums/ThreadEnum";
import $ from "jquery";
import {errorAlert, successAlert} from "@/helpers/alert";
import Swal from "sweetalert2";

export default {
  name: "ListThread",
  methods: {dateFromTimestamp},
  components: {
    Pagination
  },
  computed: {
    Status() {
      return Status
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const filter = ref({
      title: route.query.title || "",
      category_id: route.query.category_id || "",
      is_hide: route.query.is_hide || "",
      is_pinned: route.query.is_pinned || "",
    });
    const categories = ref([]);
    const page = ref(parseInt(route.query.page || "1"));
    const pageSize = ref(parseInt(route.query.page_size || "10"));
    const total = ref(0);
    const threads = ref([]);
    onMounted(async () => {
      await store.dispatch(Actions.GET_LIST_CATEGORY, {
        page: 1,
        pageSize: 1000
      }).then((res) => {
        categories.value = res.categories;
      })
      await getListThread();
      await nextTick(() => {
        $("#categories").select2({
          theme: 'classic',
          width: '100%',
          allowClear: true,
          placeholder: '-- Choose category --'
        }).on("select2:select", (event) => {
          filter.value.category_id = $(event.target).val();
        }).on("select2:unselect", (event) => {
          filter.value.category_id = $(event.target).val();
        }).val(filter.value?.category_id)
        .trigger("change");
      })
    });

    const getListThread = async () => {
      const params = {
        ...filter.value,
        page: page.value,
        pageSize: pageSize.value
      };

      await store.dispatch(Actions.GET_LIST_THREAD, params)
        .then((res) => {
          threads.value = res.threads;
          total.value = res.total;
        }).catch((err) => {
          console.log('error', err);
      })
    }

    const submitForm = () => {
      page.value = 1;
      getListThread();
      updateRoute();
    }

    const clearForm = () => {
      filter.value = {};
      page.value = 1;
      getListThread();
      updateRoute();
    }

    const changePage = (val) => {
      page.value = val;
      updateRoute();
      getListThread();
    }

    const toggleHideThread = async (thread) => {
      await store.dispatch(Actions.UPDATE_THREAD, {
        id: thread.id,
        data: {
          isHide: thread.isHide ? 0 : 1,
        }
      }).then(() => {
        getListThread();
        successAlert("Update success");
      })
          .catch(() => {
            errorAlert(store.getters.getErrors);
            getListThread();
          });
    }

    const togglePinThread = async (thread) => {
      await store.dispatch(Actions.UPDATE_THREAD, {
        id: thread.id,
        data: {
          isPinned: thread.isPinned ? 0 : 1,
        }
      }).then(() => {
        getListThread();
        successAlert("Update success");
      })
          .catch(() => {
            errorAlert(store.getters.getErrors);
            getListThread();
          });
    }

    const updateRoute = (isClear = false) => {
      const opts = {
        path: route.path,
      };
      if (!isClear) {
        opts["query"] = {
          ...filter.value,
          page: page.value,
          page_size: pageSize.value,
        };
      }
      router.push(opts);
    };

    const actionDelete = (id) => {
      Swal.fire({
        title: 'Do you want to delete?',
        showCancelButton: true,
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(Actions.DELETE_THREAD, id)
              .then(() => {
                Swal.fire('Deleted!', '', 'success')
                getListThread();
              })
              .catch(() => {
                Swal.fire('Error!', '', 'error')
              });
        }
      })
    }

    return {
      categories,
      getListThread,
      StatusBadgeCodeEnum,
      threads,
      name,
      page,
      pageSize,
      total,
      filter,
      submitForm,
      clearForm,
      updateRoute,
      changePage,
      toggleHideThread,
      togglePinThread,
      actionDelete
    }
  }
};
</script>

<style>
</style>
