<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Edit Administrator</h1>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Info</h3>
            </div>
            <Form
                @submit="updateAdministrator"
                :validation-schema="schema"
                v-slot="{ errors }"
            >
              <FormAdministrator
                  :administrator="administrator"
                  :errors="errors"
                  :readonly="true"
              >
              </FormAdministrator>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </Form>
          </div>
        </div>

      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {Form} from "vee-validate";
import * as Yup from "yup";
import {Actions} from "@/store/enums/StoreEnum";
import Swal from "sweetalert2";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import FormAdministrator from "@/pages/administrator/FormAdministrator.vue";

export default defineComponent({
  name: "EditAdministrator",
  components: {
    FormAdministrator,
    Form
  },
  computed: {

  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const administrator = ref({});

    onMounted(() => {
      getDetail();
    });

    const schema = Yup.object().shape({
      email: Yup.string()
          .email(),
      passwordConfirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    const updateAdministrator = () => {
      delete administrator.value?.passwordConfirmation;
      store.dispatch(Actions.UPDATE_ADMINISTRATOR, {
        id: route.params.id,
        data: administrator.value
      }).then(() => {
        Swal.fire({
          text: "Update Success",
          icon: "success",
          buttonsStyling: true,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        });
        router.push({ name: "administrator.index" });
      })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors,
              icon: "error",
              buttonsStyling: true,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    const getDetail = () => {
      store.dispatch(Actions.DETAIL_ADMINISTRATOR, route.params.id)
          .then((data) => {
            administrator.value = data.administrator;
          });
    }

    return {
      administrator,
      updateAdministrator,
      schema,
      getDetail
    }
  }
});
</script>

<style>
</style>