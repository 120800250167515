<template>
  <body class="hold-transition login-page">
  <div class="login-box">
    <div class="login-logo">
      Admin Login
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg">Sign in to start your session</p>

        <Form @submit="onSubmit"
              :validation-schema="schema"
              v-slot="{ errors }"
        >
          <div class="input-group mb-3">
            <Field type="email"
                   name="email"
                   class="form-control"
                   placeholder="Email"
                   :class="errors?.email ? 'is-invalid' : ''"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
            <span v-if="errors?.email" class="error invalid-feedback" style="display: inline;">
               {{ errors?.email }}
            </span>
          </div>
          <div class="input-group mb-3">
            <Field type="password"
                   name="password"
                   class="form-control"
                   placeholder="Password"
                   :class="errors?.password ? 'is-invalid' : ''"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
            <span v-if="errors?.password" class="error invalid-feedback" style="display: inline;">
               {{ errors?.password }}
            </span>
          </div>
          <div class="row">
            <div class="col-8">
              <div class="icheck-primary">
                <input type="checkbox" id="remember">

              </div>
            </div>
            <!-- /.col -->
            <div class="col-4">
              <button type="submit" class="btn btn-primary btn-block">Sign In</button>
            </div>
            <!-- /.col -->
          </div>
        </Form>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
  <!-- /.login-box -->
  </body>
</template>

<script>
import * as Yup from "yup";
import {Field, Form} from "vee-validate";
import Swal from "sweetalert2";
import {Actions} from "@/store/enums/StoreEnum.js";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {onMounted} from "vue";

export default {
  name: "Login",
  components: {
    Form,
    Field,
  },

  mounted() {
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const onSubmit = (values) => {
      store.dispatch(Actions.LOGIN, values)
          .then(() => {
            router.push({ name: "dashboard" });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors,
              icon: "error",
              buttonsStyling: true,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    const schema = Yup.object().shape({
      email: Yup.string()
          .email()
          .max(100)
          .required(),
      password: Yup.string().max(100).required(),
    });

    onMounted(() => {
      //check if current user is authenticated
      if (store.getters.isUserAuthenticated) {
        router.push({ name: "dashboard" });
      }
    });

    return {
      schema,
      onSubmit
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");
</style>
