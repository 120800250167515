import {Action, Module, VuexModule} from "vuex-module-decorators";
import MemberLevelApi from "@/api/MemberLevelApi";

@Module
class MemberLevelModule extends VuexModule {
    @Action
    getListMemberLevel(params) {
        return MemberLevelApi.list(params);
    }

    @Action
    createMemberLevel(params) {
        return MemberLevelApi.create(params);
    }

    @Action
    detailMemberLevel(payload) {
        return MemberLevelApi.detail(payload);
    }

    @Action
    updateMemberLevel(payload) {
        const id = payload.id;
        const params = payload.data;
        return MemberLevelApi.update(id, params);
    }

    @Action
    deleteMemberLevel(id) {
        return MemberLevelApi.destroy(id);
    }
}

export { MemberLevelModule }