import Swal from "sweetalert2";

export function successAlert(message) {
    Swal.fire({
        text: message,
        icon: "success",
        timer: 2000,
        timerProgressBar: false,
    });
}

export function errorAlert(message) {
    Swal.fire({
        text: message,
        icon: "error",
        buttonsStyling: true,
        confirmButtonText: "Ok",
        customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
        },
    });
}