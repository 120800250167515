export const HttpResponseStatusCode = {
    OK : 200,
    CREATED : 201,
    FORBIDDEN : 403,
    NOT_FOUND : 404,
    INTERNAL_ERROR : 500,
    UNAUTHORIZED : 401,
}

export const ResponseCode = {
    OK : 201,
}

export const Status = {
    STATUS_ACTIVE : 1,
    STATUS_INACTIVE : 2,
}

