import {Action, Module, VuexModule} from "vuex-module-decorators";
import CategoryApi from "@/api/CategoryApi";

@Module
class CategoryModule extends VuexModule {
    @Action
    getListCategory(params) {
        return CategoryApi.list(params);
    }

    @Action
    createCategory(params) {
        return CategoryApi.create(params);
    }

    @Action
    detailCategory(payload) {
        return CategoryApi.detail(payload);
    }

    @Action
    updateCategory(payload) {
        const id = payload.id;
        const params = payload.data;
        return CategoryApi.update(id, params);
    }

    @Action
    deleteCategory(id) {
        return CategoryApi.destroy(id);
    }
}

export { CategoryModule }