<template>
  <div class="card-body">
    <div class="row mb-5">
      <div class="col-4"></div>
      <div class="col-4">
        <div class="text-center"
            v-if="memberLevel.icon">
          <img class="img-circle w-50 h-50" alt="icon"
               :src="memberLevel.icon">
        </div>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="icon"
            ref="fileInput"
            accept="image/*"
            @change="handleUpload">
          <label class="custom-file-label" for="icon">Choose Icon</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-4">
        <label for="name">Name</label>
        <Field type="text"
           name="name"
           class="form-control"
           :class="$props.errors?.name ? 'is-invalid' : ''"
           v-model="$props.memberLevel.name"
           id="name"
        >
        </Field>
        <span v-if="$props.errors?.name" class="invalid-feedback">
          {{ $props.errors?.name }}
        </span>
      </div>
      <div class="form-group col-4">
        <label for="nameEn">English Name</label>
        <Field type="text"
           name="nameEn"
           class="form-control"
           :class="$props.errors?.nameEn ? 'is-invalid' : ''"
           v-model="$props.memberLevel.nameEn"
           id="nameEn"
        >
        </Field>
        <span v-if="$props.errors?.nameEn" class="invalid-feedback">
          {{ $props.errors?.nameEn }}
        </span>
      </div>
      <div class="form-group col-4">
        <label for="minStKlay">Min st klay</label>
        <input type="number"
           name="minStKlay"
           class="form-control"
           v-model="$props.memberLevel.minStKlay"
           id="minStKlay"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <div class="custom-control custom-checkbox"
        v-for="(permission, display) in PermissionsEnum" :key="permission">
          <input class="custom-control-input"
             type="checkbox"
             :id="permission"
             :value="permission"
             v-model="permissions">
          <label :for="permission" class="custom-control-label">{{ display }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, ref, watch} from "vue";
import {Field} from "vee-validate";
import {boolean} from "yup";
import {PermissionsEnum, basePermissions} from "@/enums/PermissionsEnum";
import StorageApi from "@/api/StorageApi";
import $ from "jquery"

export default defineComponent({
  name: "FormMemberLevel",
  components: {
    Field
  },
  props: {
    memberLevel : {},
    errors : {},
    readOnly: boolean,
  },
  emits: ["updatePermissions", "updateIcon"],
  setup(props, {emit}) {
    const permissions = ref([]);
    const permissionsValue = computed(() => props.memberLevel.permissions);

    watch(
        () => permissionsValue.value,
        (newPermissions) => {
          Object.values(PermissionsEnum).forEach((per) => {
            if (newPermissions?.[per] === true) {
              permissions.value.push(per)
            }
          })
        }
    )

    watch(
        () => permissions.value,
        (newPermissions) => {
          Object.values(PermissionsEnum).forEach((per) => {
            basePermissions[per] = !!newPermissions.includes(per);
          })
          emit("updatePermissions", basePermissions)
        }
    )

    const fileInput = ref(null);
    const handleUpload = async (e) => {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file, file.name);
      await StorageApi.upload(formData).then((res) => {
        if (res.success) {
          emit("updateIcon", res.data.fileUrl)
        }
      })
      const input = fileInput.value;
      if (input) {
        $(input).val("");
      }
    };

    return {
      PermissionsEnum,
      permissions,
      fileInput,
      handleUpload
    }
  }
});
</script>
