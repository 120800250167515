export const Actions = {
    VERIFY_AUTH : "verifyAuth",
    LOGIN : "login",
    LOGOUT : "logout",
    GET_LIST_PLAN : "getListPlan",
    CREATE_PLAN : "createPlan",
    UPDATE_PLAN : "updatePlan",
    DELETE_PLAN : "deletePlan",
    DETAIL_PLAN : "detailPlan",
    GET_LIST_THEO_PACKAGE : "getListTheoPackage",
    CREATE_THEO_PACKAGE : "createTheoPackage",
    UPDATE_THEO_PACKAGE : "updateTheoPackage",
    DELETE_THEO_PACKAGE : "deleteTheoPackage",
    DETAIL_THEO_PACKAGE : "detailTheoPackage",
    GET_LIST_THEO_PRICE : "getListTheoPrice",
    CREATE_THEO_PRICE : "createTheoPrice",
    UPDATE_THEO_PRICE : "updateTheoPrice",
    DELETE_THEO_PRICE : "deleteTheoPrice",
    DETAIL_THEO_PRICE : "detailTheoPrice",
    GET_LIST_CONTENT_PRICING : "getListContentPricing",
    CREATE_CONTENT_PRICING : "createContentPricing",
    UPDATE_CONTENT_PRICING : "updateContentPricing",
    DELETE_CONTENT_PRICING : "deleteContentPricing",
    DETAIL_CONTENT_PRICING : "detailContentPricing",
    GET_LIST_BANNER : "getListBanner",
    CREATE_BANNER : "createBanner",
    UPDATE_BANNER : "updateBanner",
    DELETE_BANNER : "deleteBanner",
    DETAIL_BANNER : "detailBanner",
    UPLOAD_FILE: 'uploadFile',
    GET_LIST_CURATION : "getListCuration",
    CREATE_CURATION : "createCuration",
    UPDATE_CURATION : "updateCuration",
    DELETE_CURATION : "deleteCuration",
    DETAIL_CURATION : "detailCuration",
    GET_LIST_ADMINISTRATOR : "getListAdministrator",
    CREATE_ADMINISTRATOR : "createAdministrator",
    UPDATE_ADMINISTRATOR : "updateAdministrator",
    DELETE_ADMINISTRATOR : "deleteAdministrator",
    DETAIL_ADMINISTRATOR : "detailAdministrator",
    GET_LIST_MEMBER_LEVEL : "getListMemberLevel",
    CREATE_MEMBER_LEVEL : "createMemberLevel",
    UPDATE_MEMBER_LEVEL : "updateMemberLevel",
    DELETE_MEMBER_LEVEL : "deleteMemberLevel",
    DETAIL_MEMBER_LEVEL : "detailMemberLevel",
    GET_LIST_CATEGORY : "getListCategory",
    CREATE_CATEGORY : "createCategory",
    UPDATE_CATEGORY : "updateCategory",
    DELETE_CATEGORY : "deleteCategory",
    DETAIL_CATEGORY : "detailCategory",
    GET_LIST_USER : "getListUser",
    UPDATE_USER : "updateUser",
    DETAIL_USER : "detailUser",
    GET_LIST_THREAD : "getListThread",
    UPDATE_THREAD : "updateThread",
    DETAIL_THREAD : "detailThread",
    DELETE_THREAD : "deleteThread",
    GET_LIST_THREAD_REPORT : "getListThreadReport",
    DETAIL_THREAD_REPORT : "detailThreadReport",
    GET_LIST_COMMENT_REPORT : "getListCommentReport",
    TOGGLE_COMMENT : "toggleComment",
    DETAIL_COMMENT_REPORT : "detailCommentReport",
    GET_LIST_COLOR : "getListColor",
    CREATE_COLOR : "createColor",
    UPDATE_COLOR : "updateColor",
    DELETE_COLOR : "deleteColor",
    DETAIL_COLOR : "detailColor",
    GET_LIST_TONE_MOOD : "getListToneMood",
    CREATE_TONE_MOOD : "createToneMood",
    UPDATE_TONE_MOOD : "updateToneMood",
    DELETE_TONE_MOOD : "deleteToneMood",
    DETAIL_TONE_MOOD : "detailToneMood",
}

export const Mutations = {
    SET_AUTH: "setAuth",
    SET_ERROR: "setError",
    PURGE_AUTH: "destroyAuth",
    SET_LIST_PLAN: "setListPlan",
    SET_LIST_THEO_PACKAGE: "setListTheoPackage",
}
