<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">View report</h1>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card card-primary">
            <div class="card-body">
              {{threadReport?.reason}}
            </div>
            <div class="card-footer">
              <button type="button" class="btn btn-primary mr-2"
                @click="toggleHideThread(false)">
                Show
              </button>
              <button type="button" class="btn btn-warning mr-2"
                @click="toggleHideThread(true)">
                Hide
              </button>
              <button type="button" class="btn btn-default"
                @click="router.go(-1)">Back
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
</template>

<script>
import {onMounted, ref} from "vue";
import {Actions} from "@/store/enums/StoreEnum";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "ViewThreadReport",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const threadReport = ref({
      threadId: "",
      reason: ""
    })

    onMounted(async () => {
      getDetail();
    });

    const getDetail = () => {
      store.dispatch(Actions.DETAIL_THREAD_REPORT, route.params.id)
        .then((data) => {
          threadReport.value = data.threadReport;
        });
    }

    const toggleHideThread = (isHide) => {
      const title = isHide ? "hide" : "show";
      Swal.fire({
        text: `Do you want ${title} thread`,
        icon: isHide ? "info" : "warning",
        buttonsStyling: true,
        confirmButtonText: "OK",
        showCloseButton: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (res) => {
        if (res.isConfirmed) {
          await store.dispatch(Actions.UPDATE_THREAD, {
            id: threadReport.value.threadId,
            data: {
              isHide: isHide,
            }
          }).then(() => {
            Swal.fire({
              text: "Update Success",
              icon: "success",
              buttonsStyling: true,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success",
              },
            }).then(async () => {
              await router.push({ name: "thread_report.index" });
            });
          })
        }
      });
    }

    return {
      router,
      threadReport,
      toggleHideThread,
    }
  }
};
</script>

<style>
</style>
