import {Action, Module, VuexModule} from "vuex-module-decorators";
import AdminApi from "@/api/AdminApi";

@Module
class AdministratorModule extends VuexModule {
    @Action
    getListAdministrator(params) {
        return AdminApi.list(params);
    }

    @Action
    createAdministrator(params) {
        return AdminApi.create(params);
    }

    @Action
    detailAdministrator(payload) {
        return AdminApi.detail(payload);
    }

    @Action
    updateAdministrator(payload) {
        const id = payload.id;
        const params = payload.data;
        return AdminApi.update(id, params);
    }

    @Action
    deleteAdministrator(id) {
        return AdminApi.destroy(id);
    }
}

export { AdministratorModule }