import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import  * as JwtService from "@/services/JwtService";
import AuthAPI from "@/api/AuthApi";
import BaseAPI from "@/api/BaseAPI";
import {Mutations} from "@/store/enums/StoreEnum";

@Module
class AuthModule extends VuexModule {
  errors = [];
  user = JwtService.getUserInfo();
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser() {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated() {
    return this.isAuthenticated;
  }

  /**
   * Get authentication errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }

  @Mutation
  setAuth(data) {
    const now = new Date().getTime();
    this.isAuthenticated = true;
    this.user = data.user;
    this.errors = [];
    JwtService.saveToken(data.accessToken);
    JwtService.saveRefreshToken(data.refreshToken);
    JwtService.saveExpiresIn((data.expiresIn * 1000 + now)?.toString());
    JwtService.saveUserInfo(JSON.stringify(data.user));
  }

  @Action
  login(credentials) {
    return AuthAPI.login(credentials);
  }

  @Action
  verifyAuth() {
    const now = new Date();
    if (JwtService.getRefreshToken() && JwtService.getExpiresIn()) {
      const expiresIn = parseInt(JwtService.getExpiresIn() || "");
      const currentTime = now.getTime();
      if (expiresIn < currentTime) {
        this.context.commit(Mutations.PURGE_AUTH);
      } else if (
          expiresIn > currentTime &&
          expiresIn - 5 * 60 * 1000 < currentTime
      ) {
        //console.log('refresh token here');
        //return AuthAPI.refreshToken({});
        //BaseAPI.setHeader();
        this.context.commit(Mutations.PURGE_AUTH);
      } else {
        BaseAPI.setHeader();
      }
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Mutation
  destroyAuth() {
    this.isAuthenticated = false;
    this.user = {} ;
    this.errors = [];
    JwtService.destroyToken();
    JwtService.destroyRefreshToken();
    JwtService.destroyExpiresIn();
    JwtService.destroyUserInfo();
  }

  @Action
  logout() {
    return AuthAPI.logout();
  }
}

export { AuthModule }