import { createWebHistory, createRouter } from "vue-router";
import Home from "@/pages/Home.vue";
import Dashboard from "@/pages/Dashboard.vue";
import Main from "@/layouts/Main.vue";
import Login from "@/pages/Login.vue";
import AuthMiddleware from "@/router/middleware/AuthMiddleware";
import ListAdministrator from "@/pages/administrator/ListAdministrator.vue";
import CreateAdministrator from "@/pages/administrator/CreateAdministrator.vue";
import EditAdministrator from "@/pages/administrator/EditAdministrator.vue";
import ListCategory from "@/pages/category/ListCategory.vue";
import CreateCategory from "@/pages/category/CreateCategory.vue";
import EditCategory from "@/pages/category/EditCategory.vue";
import ListMemberLevel from "@/pages/member_level/ListMemberLevel.vue";
import CreateMemberLevel from "@/pages/member_level/CreateMemberLevel.vue";
import EditMemberLevel from "@/pages/member_level/EditMemberLevel.vue";
import ListUser from "@/pages/user/ListUser.vue";
import EditUser from "@/pages/user/EditUser.vue";
import ListThread from "@/pages/thread/ListThread.vue";
import EditThread from "@/pages/thread/EditThread.vue";
import ListThreadReport from "@/pages/thread_report/ListThreadReport.vue";
import ViewThreadReport from "@/pages/thread_report/ViewThreadReport.vue";
import ListCommentReport from "@/pages/thread_comment_report/ListCommentReport.vue";
import ViewCommentReport from "@/pages/thread_comment_report/ViewCommentReport.vue";

const routes = [
    {
        path: "/",
        redirect: "/dashboard",
        component: Main,
        name: "home",
        children: [
            {
                path: "/home",
                name: "home",
                component: Home,
            },
            {
                path: "/dashboard",
                name: "dashboard",
                component: Dashboard,
            },
            {
                path: "/administrator/list",
                name: "administrator.index",
                component: ListAdministrator,
            },
            {
                path: "/administrator/create",
                name: "administrator.create",
                component: CreateAdministrator,
            },
            {
                path: "/administrator/:id/edit",
                name: "administrator.edit",
                component: EditAdministrator,
            },
            {
                path: "/member-level/list",
                name: "member_level.index",
                component: ListMemberLevel,
            },
            {
                path: "/member-level/create",
                name: "member_level.create",
                component: CreateMemberLevel,
            },
            {
                path: "/member-level/:id/edit",
                name: "member_level.edit",
                component: EditMemberLevel,
            },
            {
                path: "/category/list",
                name: "category.index",
                component: ListCategory,
            },
            {
                path: "/category/create",
                name: "category.create",
                component: CreateCategory,
            },
            {
                path: "/category/:id/edit",
                name: "category.edit",
                component: EditCategory,
            },
            {
                path: "/user/list",
                name: "user.index",
                component: ListUser,
            },
            {
                path: "/user/:id/edit",
                name: "user.edit",
                component: EditUser,
            },
            {
                path: "/thread/list",
                name: "thread.index",
                component: ListThread,
            },
            {
                path: "/thread/:id/edit",
                name: "thread.edit",
                component: EditThread,
            },
            {
                path: "/thread-report/list",
                name: "thread_report.index",
                component: ListThreadReport,
            },
            {
                path: "/thread-report/:id/view",
                name: "thread_report.view",
                component: ViewThreadReport,
            },
            {
                path: "/thread-comment-report/list",
                name: "thread_comment_report.index",
                component: ListCommentReport,
            },
            {
                path: "/thread-comment-report/:id/view",
                name: "thread_comment_report.view",
                component: ViewCommentReport,
            },
        ]
    },
    {
        path: "/login",
        component: Login,
        name: "login",
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    AuthMiddleware.validate(to, from, next);
});
export default router;
