<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Create Admin</h1>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <section class="content">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Info</h3>
            </div>
            <Form
                @submit="createAdministrator"
                :validation-schema="schema"
                v-slot="{ errors }"
            >
              <FormAdministrator
                  :administrator="administrator"
                  :errors="errors"
              >
              </FormAdministrator>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {Form} from "vee-validate";
import * as Yup from "yup";
import {Actions} from "@/store/enums/StoreEnum";
import Swal from "sweetalert2";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import FormAdministrator from "@/pages/administrator/FormAdministrator.vue";

export default defineComponent({
  name: "CreateAdministrator",
  components: {
    FormAdministrator,
    Form
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const administrator = ref({});

    onMounted(() => {
    });

    const schema = Yup.object().shape({
      username: Yup.string()
          .required(),
      email: Yup.string()
          .required()
          .email(),
      password: Yup.string()
          .required('Password is required'),
      passwordConfirmation: Yup.string()
          .required()
          .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    const createAdministrator = () => {
      store.dispatch(Actions.CREATE_ADMINISTRATOR, {...administrator.value})
          .then(() => {
            Swal.fire({
              text: "Create Success",
              icon: "success",
              buttonsStyling: true,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success",
              },
            });
            router.push({ name: "administrator.index" });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors,
              icon: "error",
              buttonsStyling: true,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    return {
      schema,
      administrator,
      createAdministrator,
    }
  }
});
</script>

<style>
</style>