<template>
  <div class="card-body">
    <div class="row">
      <div class="form-group col-4">
        <label for="name">Name</label>
        <Field type="text"
               name="name"
               class="form-control"
               :class="$props.errors?.name ? 'is-invalid' : ''"
               v-model="$props.category.name"
               id="name"
        >
        </Field>
        <span v-if="$props.errors?.name" class="invalid-feedback">
          {{ $props.errors?.name }}
        </span>
      </div>
      <div class="form-group col-4">
        <label for="nameEn">English Name</label>
        <Field type="text"
               name="nameEn"
               class="form-control"
               :class="$props.errors?.nameEn ? 'is-invalid' : ''"
               v-model="$props.category.nameEn"
               id="nameEn"
        >
        </Field>
        <span v-if="$props.errors?.nameEn" class="invalid-feedback">
          {{ $props.errors?.nameEn }}
        </span>
      </div>
      <div class="form-group col-2">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="isPinned"
             true-value="1"
             false-value="0"
             v-model="$props.category.isPinned">
          <label class="custom-control-label" for="isPinned">Pinned</label>
        </div>
      </div>
      <div class="form-group col-2">
        <label for="memberLevelIds">Order no</label>
        <input type="number" class="form-control" name="orderNo" v-model="$props.category.orderNo"/>
      </div>
      <div class="form-group col-4">
        <label for="memberLevelIds">Member Level</label>
        <select class="form-control" name="memberLevelIds" id="memberLevelIds" multiple
          v-model="$props.category.memberLevelIds">
          <option v-for="(memberLevel, index) in memberLevels"
            :value="memberLevel.id"
            :key="index">
            {{ memberLevel.name }}
          </option>
        </select>
      </div>
      <div class="form-group col-4">
        <label for="description">Description</label>
        <textarea class="form-control" name="description" id="description" rows="5"
            v-model="$props.category.description">
        </textarea>
      </div>
      <div class="form-group col-4">
        <label for="descriptionEn">English Description</label>
        <textarea class="form-control" name="descriptionEn" id="descriptionEn" rows="5"
            v-model="$props.category.descriptionEn">
        </textarea>
      </div>
    </div>
    <hr>
    <div class="card card-default collapsed-card">
      <div class="card-header">
        <h3 class="card-title">Status badges</h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="card-body" style="display: none;">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th>Name</th>
              <th>English Name</th>
              <th>Code</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(statusBadge, index) in $props.category?.statusBadges" :key="index">
              <td>
                <input class="form-control" v-model="statusBadge.name">
              </td>
              <td>
                <input class="form-control" v-model="statusBadge.nameEn">
              </td>
              <td>
                <input class="form-control" v-model="statusBadge.code">
              </td>
              <td>
                <button type="button" class="btn btn-sm btn-danger mr-2"
                  @click="handleRemoveStatusBadge(index)"
                  v-if="$props.category?.statusBadges?.length > 1">
                  <i class="fa fa-minus"></i>
                </button>
                <button type="button" class="btn btn-sm btn-primary"
                  @click="handleAddStatusBadge"
                  v-if="index == $props.category?.statusBadges?.length - 1">
                  <i class="fa fa-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card card-default collapsed-card">
      <div class="card-header">
        <h3 class="card-title">Stages</h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="card-body" style="display: none;">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th>Name</th>
              <th>English Name</th>
              <th>Level</th>
              <th>Forum link</th>
              <th>Snapshot ID</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(stage, index) in $props.category?.stages" :key="index">
              <td>
                <input class="form-control" v-model="stage.name">
              </td>
              <td>
                <input class="form-control" v-model="stage.nameEn">
              </td>
              <td>
                <input type="number" class="form-control" v-model="stage.level">
              </td>
              <td class="text-center">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" type="checkbox" :id="`stageLink${index}`"
                    v-model="stage.forumLink"
                    true-value="1"
                    false-value="0">
                  <label :for="`stageLink${index}`" class="custom-control-label"></label>
                </div>
              </td>
              <td class="text-center">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" type="checkbox" :id="`stageSnapshotId${index}`"
                   v-model="stage.snapshotId"
                   true-value="1"
                   false-value="0">
                  <label :for="`stageSnapshotId${index}`" class="custom-control-label"></label>
                </div>
              </td>
              <td>
                <button type="button" class="btn btn-sm btn-danger mr-2"
                  @click="handleRemoveStage(index)"
                  v-if="$props.category?.stages?.length > 1">
                  <i class="fa fa-minus"></i>
                </button>
                <button type="button" class="btn btn-sm btn-primary"
                  @click="handleAddStage"
                  v-if="index == $props.category?.stages?.length - 1">
                  <i class="fa fa-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card card-default collapsed-card">
      <div class="card-header">
        <h3 class="card-title">Statuses</h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="card-body" style="display: none;">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th>Name</th>
              <th>English Name</th>
              <th>Level</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(status, index) in $props.category?.statuses" :key="index">
              <td>
                <input class="form-control" v-model="status.name">
              </td>
              <td>
                <input class="form-control" v-model="status.nameEn">
              </td>
              <td>
                <input type="number" class="form-control" v-model="status.level">
              </td>
              <td>
                <button type="button" class="btn btn-sm btn-danger mr-2"
                        @click="handleRemoveStatus(index)"
                        v-if="$props.category?.statuses?.length > 1">
                  <i class="fa fa-minus"></i>
                </button>
                <button type="button" class="btn btn-sm btn-primary"
                        @click="handleAddStatus"
                        v-if="index == $props.category?.statuses?.length - 1">
                  <i class="fa fa-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {Field} from "vee-validate";
import {nextTick, onMounted,} from "vue";
import $ from "jquery";

export default {
  name: "FormCategory",
  components: {
    Field
  },
  props: ["category", "errors", "memberLevels"],
  emits: [
    "addStatus",
    "removeStatus",
    "addStatusBadge",
    "removeStatusBadge",
    "addStage",
    "removeStage",
  ],
  setup(props, {emit}) {
    onMounted(() => {
      nextTick(() => {
        $("#memberLevelIds").select2({
          placeholder: '-- Choose member level --'
        }).on("select2:select", (event) => {
          // eslint-disable-next-line vue/no-mutating-props
          props.category.memberLevelIds = $(event.target).val();
        }).on("select2:unselect", (event) => {
          // eslint-disable-next-line vue/no-mutating-props
          props.category.memberLevelIds = $(event.target).val();
        }).val(props.category?.memberLevelIds)
          .trigger("change");
      })
    })

    const handleAddStatus = () => {
      emit("addStatus")
    };
    const handleRemoveStatus = (index) => {
      emit("removeStatus", index)
    };

    const handleAddStatusBadge = () => {
      emit("addStatusBadge")
    };
    const handleRemoveStatusBadge = (index) => {
      emit("removeStatusBadge", index)
    };

    const handleAddStage = () => {
      emit("addStage")
    };
    const handleRemoveStage = (index) => {
      emit("removeStage", index)
    };

    return {
      handleAddStatus,
      handleRemoveStatus,
      handleAddStatusBadge,
      handleRemoveStatusBadge,
      handleAddStage,
      handleRemoveStage,
    }
  }
};
</script>
