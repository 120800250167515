<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">List Thread Report</h1>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <section class="content">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <form @submit.prevent="submitForm">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-4">
                  <label class="col-form-label">Search</label>
                  <input class="form-control form-control-sm" v-model="filter.search_key">
                </div>
<!--                <div class="form-group col-4">
                  <label class="col-form-label">Thread</label>
                  <select class="form-control" id="threads" v-model="filter.thread_id">
                    <option v-for="(thread, index) in threads"
                      :value="thread.id"
                      :key="index">
                      {{ thread.title }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-4">
                  <label class="col-form-label">User</label>
                  <select class="form-control" id="users" v-model="filter.user_id">
                    <option v-for="(user, index) in users"
                      :value="user.id"
                      :key="index">
                      {{ `${user.nickname} - ${user.email}` }}
                    </option>
                  </select>
                </div>-->
              </div>
             <div class="row">
               <div class="form-group col-4">
                 <button type="submit" class="btn btn-sm btn-primary mr-2"
                     @click="submitForm">Search</button>
                 <button type="button" class="btn btn-sm btn-default"
                     @click="clearForm">Clear</button>
               </div>
             </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container-fluid card-body card">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Total record: {{ total }}</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <table id="example1" class="table table-bordered table-striped">
                <thead>
                <tr class="text-center">
                  <th>No</th>
                  <th>Thread</th>
                  <th>User</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr class="text-center" v-for="(threadReport, i) in threadReports" :key="i">
                  <td>{{ (page - 1) * pageSize + i + 1 }}</td>
                  <td>{{ threadReport?.thread?.title || '' }}</td>
                  <td>{{ threadReport?.user?.nickname || '' }}</td>
                  <td>
                    <span class="badge badge-primary" v-if="threadReport.status == 1">
                      Read
                    </span>
                    <span class="badge badge-secondary" v-else>Unread</span>
                  </td>
                  <td>{{ dateFromTimestamp(threadReport.createdAt) }}</td>
                  <td>
                    <router-link :to="{
                      name: 'thread_report.view',
                      params: {id: threadReport.id}
                    }">
                      <a title="View" class="btn btn-default btn-xs">
                        <i class="far fa-eye"></i>
                      </a>
                    </router-link>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                </tfoot>
              </table>
              <div class="text-right">
                <pagination class="text-center" v-model="page" :records="total" :per-page="pageSize" @paginate="changePage"/>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </section>
</template>

<script>
import {nextTick, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnum";
import {Status} from "@/enums/BaseEnum";
import {useRoute, useRouter} from "vue-router";
import Pagination from 'v-pagination-3';
import {dateFromTimestamp} from "@/helpers/date";
import {StatusBadgeCodeEnum} from "@/enums/ThreadEnum";
import $ from "jquery";

export default {
  name: "ListThreadReport",
  methods: {dateFromTimestamp},
  components: {
    Pagination
  },
  computed: {
    Status() {
      return Status
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const filter = ref({
      search_key: route.query.name || "",
      thread_id: route.query.thread_id || "",
      user_id: route.query.user_id || "",
    });
    const threads = ref([]);
    const users = ref([]);
    const page = ref(parseInt(route.query.page || "1"));
    const pageSize = ref(parseInt(route.query.page_size || "10"));
    const total = ref(0);
    const threadReports = ref([]);
    onMounted(async () => {
      await store.dispatch(Actions.GET_LIST_THREAD, {
        page: 1,
        pageSize: 1000
      }).then((res) => {
        threads.value = res.threads;
      })
      await store.dispatch(Actions.GET_LIST_USER, {
        page: 1,
        pageSize: 1000
      }).then((res) => {
        users.value = res.users;
      })
      await getListThreadReport();
      await nextTick(() => {
        $("#threads").select2({
          theme: 'classic',
          width: '100%',
          allowClear: true,
          placeholder: '-- Choose thread --'
        }).on("select2:select", (event) => {
          filter.value.thread_id = $(event.target).val();
        }).on("select2:unselect", (event) => {
          filter.value.thread_id = $(event.target).val();
        }).val(filter.value?.thread_id)
        .trigger("change");

        $("#users").select2({
          theme: 'classic',
          width: '100%',
          allowClear: true,
          placeholder: '-- Choose user --'
        }).on("select2:select", (event) => {
          filter.value.user_id = $(event.target).val();
        }).on("select2:unselect", (event) => {
          filter.value.user_id = $(event.target).val();
        }).val(filter.value?.user_id)
        .trigger("change");
      })
    });

    const getListThreadReport = async () => {
      const params = {
        ...filter.value,
        page: page.value,
        pageSize: pageSize.value
      };

      await store.dispatch(Actions.GET_LIST_THREAD_REPORT, params)
        .then((res) => {
          threadReports.value = res.threadReports;
          total.value = res.total;
        }).catch((err) => {
          console.log('error', err);
      })
    }

    const submitForm = () => {
      page.value = 1;
      getListThreadReport();
      updateRoute();
    }

    const clearForm = () => {
      filter.value = {};
      page.value = 1;
      getListThreadReport();
      updateRoute();
    }

    const changePage = (val) => {
      page.value = val;
      updateRoute();
      getListThreadReport();
    }
    const updateRoute = (isClear = false) => {
      const opts = {
        path: route.path,
      };
      if (!isClear) {
        opts["query"] = {
          ...filter.value,
          page: page.value,
          page_size: pageSize.value,
        };
      }
      router.push(opts);
    };

    return {
      getListThreadReport,
      StatusBadgeCodeEnum,
      threadReports,
      name,
      page,
      pageSize,
      total,
      filter,
      threads,
      users,
      submitForm,
      clearForm,
      updateRoute,
      changePage,
    }
  }
};
</script>

<style>
</style>
