<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Edit User</h1>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card card-primary">
            <Form
                @submit="updateUser"
                :validation-schema="schema"
                v-slot="{ errors }"
            >
              <FormUser
                  :user="user"
                  :errors="errors"
                  :member-levels="memberLevels"
              >
              </FormUser>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </Form>
          </div>
        </div>

      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
</template>

<script>
import {onMounted, ref} from "vue";
import {Form} from "vee-validate";
import * as Yup from "yup";
import {Actions} from "@/store/enums/StoreEnum";
import Swal from "sweetalert2";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import FormUser from "@/pages/user/FormUser.vue";

export default {
  name: "EditCategory",
  components: {
    FormUser,
    Form
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const user = ref({
      memberLevelId: "",
    });

    const memberLevels = ref([]);
    onMounted(async () => {
      getDetail();
      await store.dispatch(Actions.GET_LIST_MEMBER_LEVEL, {
        page: 1,
        pageSize: 1000,
      }).then((res) => {
        memberLevels.value = res.memberLevels;
      }).catch((err) => {
        console.log('error', err);
      })
    });

    const schema = Yup.object().shape({
      nickname: Yup.string().required(),
    });

    const updateUser = () => {
      store.dispatch(Actions.UPDATE_USER, {
        id: route.params.id,
        data: user.value
      }).then(() => {
        Swal.fire({
          text: "Update Success",
          icon: "success",
          buttonsStyling: true,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        });
        router.push({ name: "user.index" });
      })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors,
              icon: "error",
              buttonsStyling: true,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    const getDetail = () => {
      store.dispatch(Actions.DETAIL_USER, route.params.id)
          .then((data) => {
            user.value = data.user;
          });
    }

    return {
      memberLevels,
      user,
      updateUser,
      schema,
      getDetail
    }
  }
};
</script>

<style>
</style>
