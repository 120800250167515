import { createStore } from "vuex";
import {AuthModule} from "@/store/modules/AuthModule";
import {AdministratorModule} from "@/store/modules/AdministratorModule";
import {CategoryModule} from "@/store/modules/CategoryModule";
import {MemberLevelModule} from "@/store/modules/MemberLevelModule";
import {UserModule} from "@/store/modules/UserModule";
import {ThreadModule} from "@/store/modules/ThreadModule";

const store = createStore({
    modules: {
        auth: AuthModule,
        AdministratorModule: AdministratorModule,
        categoryModule: CategoryModule,
        memberLevelModule: MemberLevelModule,
        userModule: UserModule,
        threadModule: ThreadModule,
    },
});

export default store;
