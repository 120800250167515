const ID_TOKEN_KEY = "id_token";
const ID_REFRESH_TOKEN_KEY = "id_refresh_token";
const ID_EXPIRES_IN_KEY = "id_expires_in";
const ID_USER_INFO = "id_user_info";

export const getToken = () => {
    return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getRefreshToken = () => {
    return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY);
};

export const getExpiresIn = () => {
    return window.localStorage.getItem(ID_EXPIRES_IN_KEY);
};

export const saveToken = (token) => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveRefreshToken = (token) => {
    window.localStorage.setItem(ID_REFRESH_TOKEN_KEY, token);
};

export const saveExpiresIn = (value) => {
    window.localStorage.setItem(ID_EXPIRES_IN_KEY, value);
};

export const destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyRefreshToken = () => {
    window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY);
};

export const destroyExpiresIn = () => {
    window.localStorage.removeItem(ID_EXPIRES_IN_KEY);
};

export const saveUserInfo = (value) => {
    window.localStorage.setItem(ID_USER_INFO, value);
};

export const destroyUserInfo = () => {
    window.localStorage.removeItem(ID_USER_INFO);
};


export const getUserInfo = () => {
    if (window.localStorage.getItem(ID_USER_INFO)) {
        return JSON.parse(window.localStorage.getItem(ID_USER_INFO));
    }
}