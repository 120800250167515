export const PermissionsEnum = {
  VOTE: 'vote',
  REPORT_SPAM: 'report_spam',
  LIKE: 'like',
  COMMENT: 'comment',
  WRITE_FREE_DISCUSSION: 'write_free_discussion',
  AGENDA_PROPOSAL: 'agenda_proposal',
  UNPIN_THREAD: 'hide_pinned_thread',
  HIDE_THREAD: 'hide_thread',
}

export const basePermissions = {
  [PermissionsEnum.VOTE]: false,
  [PermissionsEnum.REPORT_SPAM]: false,
  [PermissionsEnum.LIKE]: false,
  [PermissionsEnum.COMMENT]: false,
  [PermissionsEnum.WRITE_FREE_DISCUSSION]: false,
  [PermissionsEnum.AGENDA_PROPOSAL]: false,
  [PermissionsEnum.UNPIN_THREAD]: false,
  [PermissionsEnum.HIDE_THREAD]: false,
}
