<template>
  <router-view />
</template>

<script>
import $ from 'jquery'

export default {
  name: "App",
  methods: {

  },
  mounted() {
    $('[data-widget="treeview"]').Treeview('init');
  }
};
</script>
