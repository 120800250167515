<template>
  <div class="row">
    <div class="col-6">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Info</h3>
        </div>
        <div class="card-body form-horizontal">
          <div class="form-group row">
            <label class="col-form-label col-4">Hide</label>
            <div class="col-8">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="isHide"
                   true-value="1"
                   false-value="0"
                   v-model="$props.thread.isHide">
                <label class="custom-control-label" for="isHide"></label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-4">Status badge code</label>
            <div class="col-8">
              <select class="form-control" v-model="$props.thread.statusBadgeCode">
                <option value="">-- Choose status badge code --</option>
                <option v-for="(statusBadge, index) in thread?.category?.statusBadges"
                        :key="index"
                        :value="statusBadge.code">
                  {{ `${statusBadge.name} - ${statusBadge.nameEn}` }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-4">Status level</label>
            <div class="col-8">
              <select class="form-control" v-model="$props.thread.statusLevel">
                <option value="">-- Choose status level --</option>
                <option v-for="(status, index) in thread?.category?.statuses"
                        :key="index"
                        :value="status.level">
                  {{ `${status.name} - ${status.nameEn}` }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-4">Stage level</label>
            <div class="col-8">
              <select class="form-control" v-model="$props.thread.stageLevel">
                <option value="">-- Choose stage level --</option>
                <option v-for="(stage, index) in thread?.category?.stages"
                        :key="index"
                        :value="stage.level">
                  {{ `${stage.name} - ${stage.nameEn}` }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6" v-if="isForumLinks">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Forum link</h3>
        </div>
        <div class="card-body">
          <div class="form-group row">
            <div class="col-8">
              <input class="form-control" v-model="$props.thread.forumLink">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6" v-if="isSnapshotId">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Snapshot ID</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col-6">
              <input class="form-control"
                     v-model="$props.thread.snapshotId">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {SnapshotTypes} from "@/enums/SnapshotEnum";
import {onMounted, ref, watch} from "vue";

export default {
  name: "FormThread",
  props: ["thread", "errors", "memberLevels"],
  setup(props) {
    const isForumLinks = ref(false);
    const isSnapshotId = ref(false);

    onMounted(() => {
      checkStage(props.thread.stageLevel);
      initSnapshotId(props.thread.stageLevel);
    })

    watch(
        () => props.thread.stageLevel,
        (level) => {
          checkStage(level)
          initSnapshotId(level);
        }
    )

    const checkStage = (stageLevel) => {
      const stage = props.thread?.category?.stages?.filter((stage) => stage.level == stageLevel)?.[0];
      if (stage) {
        isForumLinks.value = stage.forumLink == 1;
        isSnapshotId.value = stage.snapshotId == 1;
      }
    }

    const initSnapshotId = (stageLevel) => {
      const vote = props.thread?.votes?.filter((vote) => vote.stageId == stageLevel)?.[0];
      // eslint-disable-next-line vue/no-mutating-props
      props.thread.snapshotId = vote?.snapshotId || "";
    }

    return {
      isForumLinks,
      isSnapshotId,
      SnapshotTypes,
    }
  }
};
</script>
