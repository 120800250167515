<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Edit Member Level</h1>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Info</h3>
            </div>
            <Form
                @submit="updateCategory"
                :validation-schema="schema"
                v-slot="{ errors }"
            >
              <FormMemberLevel
                  :memberLevel="memberLevel"
                  :errors="errors"
                  @update-permissions="handlePermissions"
                  @update-icon="handleIcon"
              >
              </FormMemberLevel>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </Form>
          </div>
        </div>

      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {Form} from "vee-validate";
import * as Yup from "yup";
import {Actions} from "@/store/enums/StoreEnum";
import Swal from "sweetalert2";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import FormMemberLevel from "@/pages/member_level/FormMemberLevel.vue";

export default defineComponent({
  name: "EditMemberLevel",
  components: {
    FormMemberLevel,
    Form
  },
  computed: {

  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const memberLevel = ref({});

    onMounted(() => {
      getDetail();
    });

    const schema = Yup.object().shape({
      name: Yup.string().required(),
    });

    const handlePermissions = (permissions) => {
      memberLevel.value.permissions = permissions;
    }

    const handleIcon = (fileUrl) => {
      memberLevel.value.icon = fileUrl;
    }

    const updateCategory = () => {
      store.dispatch(Actions.UPDATE_MEMBER_LEVEL, {
        id: route.params.id,
        data: memberLevel.value
      }).then(() => {
        Swal.fire({
          text: "Update Success",
          icon: "success",
          buttonsStyling: true,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        });
        router.push({ name: "member_level.index" });
      })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors,
              icon: "error",
              buttonsStyling: true,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    const getDetail = () => {
      store.dispatch(Actions.DETAIL_MEMBER_LEVEL, route.params.id)
          .then((data) => {
            memberLevel.value = data.memberLevel;
          });
    }

    return {
      memberLevel,
      updateCategory,
      schema,
      handlePermissions,
      handleIcon,
      getDetail
    }
  }
});
</script>

<style>
</style>