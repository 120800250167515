<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#" class="brand-link">
      <img src="../assets/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
      <span class="brand-text font-weight-light">Admin CMS</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="../assets/img/avatar5.png" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a href="#" class="d-block">{{ admin.username ?? '' }}</a>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <div v-for="(item, i) in MenuConfig" :key="i">
            <li class="nav-header" v-show="item.heading">{{item.heading}}</li>
            <li class="nav-item" v-for="(item_lv1, index_lv1) in item.pages" :key="index_lv1">
              <router-link
                  v-slot="{ isActive , isExactActive}"
                  :to="item_lv1.route"
              >
                <a href="#"
                   :class="[isActive && 'active', isExactActive && 'active']"
                   class="nav-link" v-if="!item_lv1.sub || item_lv1.sub.length === 0">
                  <i :class="item_lv1.fontIcon" class=" nav-icon"></i>
                  <p class="ml-1"> {{item_lv1.sectionTitle}}</p>
                </a>
              </router-link>

              <a href="#" class="nav-link" v-if="item_lv1.sub && item_lv1.sub.length > 0">
                <i class="nav-icon " :class="item_lv1.fontIcon"></i>
                <p>
                  {{item_lv1.sectionTitle}}
                  <i
                      class="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul v-if="item_lv1.sub && item_lv1.sub.length > 0" class="nav nav-treeview">
                <li class="nav-item" v-for="(item_level2, index_2) in item_lv1.sub" :key="index_2">
                  <router-link
                      v-slot="{ isActive , isExactActive}"
                      :to="item_lv1.route"
                  >
                    <a href="#" class="nav-link"
                       :class="[isActive && 'active', isExactActive && 'active']"
                    >
                      <i :class="item_lv1.fontIcon" class=" nav-icon"></i>
                      <p> {{ item_level2.heading}}</p>
                    </a>
                  </router-link>
                </li>


              </ul>

            </li>

          </div>

        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
//import MenuConfig from "@/config/menu";
import MenuConfig from "@/config/menu";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: 'Sidebar',
  methods: {

  },
  mounted(){
  },
  setup() {
    const store = useStore();

    const admin = computed(() => {
      return store.getters.currentUser
    });

    return {
      MenuConfig,
      admin
    }
  }
}
</script>

<style>

</style>