<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-info">
        <div class="card-header">
          <h3 class="card-title">History</h3>
        </div>
        <div class="card-body">
          <table class="table table-borderless">
            <tbody>
              <tr v-for="(history, index) in histories" :key="index">
                <td>{{ history.createdAt ? moment.unix(history.createdAt).format('DD/MM/YYYY HH:mm:ss') : '' }}</td>
                <td>{{ history.stepNameEn }}</td>
                <td>
                  <a :href="history.forumUrl || '#'">
                    <i class="fa fa-arrow-alt-circle-up mr-2"></i>{{ history.stepName }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "ThreadHistory",
  props: ["histories"],
  setup() {
    return {
      moment,
    }
  }
}
</script>
