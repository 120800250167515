import DefaultApi from "@/api/DefaultApi";
import store from "@/store";
import {Mutations} from "@/store/enums/StoreEnum";

export default class ThreadCommentReportApi extends DefaultApi {
    static url = '/thread-comment-report';

    static toggleComment(id) {
        return new Promise((resolve, reject) => {
            this.post(`${this.url}/toggle-comment/${id}`).then((data) => {
                if (data.success) {
                    resolve(data.data);
                } else {
                    store.commit(Mutations.SET_ERROR, data.message);
                    reject();
                }
            });
        });
    }
}
