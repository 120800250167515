<template>
  <body class="hold-transition sidebar-mini layout-fixed">
  <div class="wrapper">

    <Navbar></Navbar>
    <Sidebar></Sidebar>

    <div class="content-wrapper">

      <ContentHeader></ContentHeader>

      <section class="content">
        <div class="container-fluid">
          <router-view />
        </div>
      </section>

    </div>

    <Footer></Footer>
    <ControlSidebar></ControlSidebar>

  </div>
  </body>
</template>

<script>
import $ from 'jquery'
import "../../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"
import "../../node_modules/admin-lte/plugins/select2/css/select2.min.css"
import ContentHeader from "@/layouts/ContentHeader.vue";
import Navbar from "@/layouts/Navbar.vue";
import Sidebar from "@/layouts/Sidebar.vue";
import Footer from "@/layouts/Footer.vue";
import ControlSidebar from "@/layouts/ControlSidebar.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import {onMounted, watch} from "vue";

export default {
  name: "Main",
  components: {
    ContentHeader,
    Navbar,
    Sidebar,
    Footer,
    ControlSidebar
  },
  methods: {
    initializeSelect2() {
      //Initialize Select2 Elements
      // $(".select2").select2();

      //Initialize Select2 Elements
      // $(".select2bs4").select2({
      //   theme: "bootstrap4",
      //   closeOnSelect : true,
      //   allowHtml: true,
      //   allowClear: true,
      //   tags: true
      // });
    },
  },
  mounted() {
    //this.initializeSelect2()
    $('[data-widget="treeview"]').Treeview('init');
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    onMounted(() => {
      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        router.push({ name: "login" });
      }
    });

    watch(
        () => route.path,
        () => {
          if (!store.getters.isUserAuthenticated) {
            router.push({ name: "login" });
          }
        }
    );
  }

};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");
</style>
