import axios from "axios";
import * as JwtService from "@/services/JwtService";
import * as qs from "qs";
import {HttpResponseStatusCode} from "@/enums/BaseEnum";
import store from "@/store";
import { Mutations} from "@/store/enums/StoreEnum";
import router from "@/router";

export default class BaseApiClient {
    constructor() {
        axios.defaults.baseURL = process.env.VUE_APP_API_CLIENT_URL;
        console.log('process.env.VUE_APP_API_CLIENT_URL', process.env.VUE_APP_API_CLIENT_URL)
    }

    static setHeader(headers = {}) {
        axios.defaults.headers.common[
            "Authorization"
            ] = `Bearer ${JwtService.getToken()}`;
        Object.assign(axios.defaults.headers, headers);
    }

    static get(path, data = {}) {
        axios.defaults.baseURL = process.env.VUE_APP_API_CLIENT_URL;

        return new Promise((resolve) => {
            axios
                .get(path, {
                    params: data,
                    paramsSerializer: (params) => {
                        return qs.stringify(params);
                    },
                })
                .then((response) => {
                    resolve(this._handleResponse(response));
                })
                .catch(({ response, message }) => {
                    resolve({
                        success: false,
                        message: this._handleError(response, message),
                    });
                });
        });
    }

    static post(path, data) {
        axios.defaults.baseURL = process.env.VUE_APP_API_CLIENT_URL;

        return new Promise((resolve) => {
            axios
                .post(path, data)
                .then((response) => {
                    resolve(this._handleResponse(response));
                })
                .catch(({ response, message }) => {
                    resolve({
                        success: false,
                        message: this._handleError(response, message),
                    });
                });
        });
    }

    static patch(path, data) {
        axios.defaults.baseURL = process.env.VUE_APP_API_CLIENT_URL;

        return new Promise((resolve) => {
            axios
                .patch(path, data)
                .then((response) => {
                    resolve(this._handleResponse(response));
                })
                .catch(({ response, message }) => {
                    resolve({
                        success: false,
                        message: this._handleError(response, message),
                    });
                });
        });
    }

    static delete(path) {
        axios.defaults.baseURL = process.env.VUE_APP_API_CLIENT_URL;

        return new Promise((resolve) => {
            axios
                .delete(path)
                .then((response) => {
                    resolve(this._handleResponse(response));
                })
                .catch(({ response, message }) => {
                    resolve({
                        success: false,
                        message: this._handleError(response, message),
                    });
                });
        });
    }

    static paramsToString(data) {
        const params = new URLSearchParams();
        for (const key in data) {
            if (Array.isArray(data[key])) {
                for (let i = 0; i < data[key].length; i++) {
                    params.append(`${key}[${i}]`, data[key][i]);
                }
            } else {
                params.append(key, data[key]);
            }
        }
        return params.toString();
    }

    static _handleError = (response, message) => {
        if (response.status === HttpResponseStatusCode.UNAUTHORIZED) {
            //store.dispatch(Actions.VERIFY_AUTH);
            store.commit(Mutations.PURGE_AUTH);
            router.push({ name: "login" });
        }
        if (response.data.message) {
            return response.data.message;
        }
        return message;
    }

    static _handleResponse(response) {
        if (response.status !== HttpResponseStatusCode.OK
        && response.status !== HttpResponseStatusCode.CREATED) {
            return {
                success: false,
                data: response.data,
            };
        }
        const data = response.data;
        if (
            data.code === 200 ||
            data.code === 201
        ) {
            return {
                success: true,
                data: data.data,
            };
        } else {
            return {
                success: false,
                message: data.message,
                data: data.data,
            };
        }
    }
}
