<template>
  <div class="card card-default">
    Home
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style>
</style>