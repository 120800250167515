<template>
  <div class="card-body">
    <div class="row">
      <div class="col-6 form-horizontal">
        <div class="form-group row">
          <label class="col-4 form-check-label" for="nickname">Nickname</label>
          <div class="col-8">
            <Field type="text"
                   name="nickname"
                   class="form-control"
                   :class="$props.errors?.nickname ? 'is-invalid' : ''"
                   v-model="$props.user.nickname"
                   id="nickname"
            >
            </Field>
            <span v-if="$props.errors?.nickname" class="invalid-feedback">
              {{ $props.errors?.nickname }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 form-check-label" for="email">Email</label>
          <div class="col-8">
            <input type="email"
                   name="email"
                   class="form-control"
                   v-model="$props.user.email"
                   id="email"
                   disabled
            >
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 form-check-label" for="memberLevelId">Member Level</label>
          <div class="col-8">
            <select class="form-control" name="memberLevelId" id="memberLevelId"
                    v-model="$props.user.memberLevelId">
              <option v-for="(memberLevel, index) in memberLevels"
                      :value="memberLevel.id"
                      :key="index">
                {{ memberLevel.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
            <label class="col-4"></label>
            <div class="col-8">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="status"
                       true-value="1"
                       false-value="0"
                       v-model="$props.user.status">
                <label class="custom-control-label" for="status">Status</label>
              </div>
            </div>
          </div>
      </div>
      <div class="col-6">
        <div class="text-center mb-2">
          <img class="img-circle elevation-2" :src="user.avatar" alt="User Avatar" v-if="user.avatar">
          <img class="img-circle elevation-2" src="../../assets/img/user1-128x128.jpg" alt="User Avatar" v-else>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-primary">Upload</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {nextTick, onMounted,} from "vue";
import {Field} from "vee-validate";
import $ from "jquery";

export default {
  name: "FormUser",
  components: {
    Field
  },
  props: ["user", "errors", "memberLevels"],
  setup(props) {
    onMounted(() => {
      nextTick(() => {
        $("#memberLevelId").select2({
          theme: 'classic',
          width: '100%',
          placeholder: '-- Choose member level --'
        }).on("select2:select", (event) => {
          // eslint-disable-next-line vue/no-mutating-props
          props.user.memberLevelId = $(event.target).val();
        }).on("select2:unselect", (event) => {
          // eslint-disable-next-line vue/no-mutating-props
          props.user.memberLevelId = $(event.target).val();
        }).val(props.user?.memberLevelId)
          .trigger("change");
      })
    })

    return {
    }
  }
};
</script>
