<template>
  <div class="card-body">
    <div class="row">
      <div class="form-group col-4">
        <label for="username">Username</label>
        <Field type="text"
           name="username"
           class="form-control"
           :class="$props.errors?.username ? 'is-invalid' : ''"
           v-model="$props.administrator.username"
           id="username"
        >
        </Field>
        <span v-if="$props.errors?.username" class="invalid-feedback">
          {{ $props.errors?.username }}
        </span>
      </div>
      <div class="form-group col-4">
        <label for="email">Email</label>
        <Field type="text"
           name="email"
           class="form-control"
           :class="$props.errors?.email ? 'is-invalid' : ''"
           v-model="$props.administrator.email"
           :disabled="readonly"
           id="email"
        >
        </Field>
        <span v-if="$props.errors?.email" class="invalid-feedback">
          {{ $props.errors?.email }}
        </span>
      </div>
      <div class="form-group col-4">
        <label for="fullName">Full name</label>
        <input
           name="fullName"
           class="form-control"
           v-model="$props.administrator.fullName"
           id="fullName"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-4">
        <label for="username">Password</label>
        <Field type="password"
           name="password"
           class="form-control"
           :class="$props.errors?.password ? 'is-invalid' : ''"
           v-model="$props.administrator.password"
           :disabled="readonly && !changePassword"
           id="password"
        >
        </Field>
        <span v-if="$props.errors?.password" class="invalid-feedback">
          {{ $props.errors?.password }}
        </span>
      </div>
      <div class="form-group col-4">
        <label for="username">Password Confirmation</label>
        <Field type="password"
           name="passwordConfirmation"
           class="form-control"
           :class="$props.errors?.passwordConfirmation ? 'is-invalid' : ''"
           v-model="$props.administrator.passwordConfirmation"
           :disabled="readonly && !changePassword"
           id="passwordConfirmation"
        >
        </Field>
        <span v-if="$props.errors?.passwordConfirmation" class="invalid-feedback">
          {{ $props.errors?.passwordConfirmation }}
        </span>
      </div>
      <div class="form-group col-2" v-if="readonly">
        <label class="col-form-label">Change password</label>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" v-model="changePassword">
        </div>
      </div>
      <div class="form-group col-2">
        <label class="col-form-label">Status</label>
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="status"
             true-value="1"
             false-value="0"
             v-model="$props.administrator.status">
          <label class="custom-control-label" for="status"></label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import {Field} from "vee-validate";
import {boolean} from "yup";

export default defineComponent({
  name: "FormMemberLevel",
  components: {
    Field
  },
  props: {
    administrator : {},
    errors : {},
    readonly: boolean
  },
  setup() {
    const changePassword = ref(false);

    return {
      changePassword,
    }
  }
});
</script>
