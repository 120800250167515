import BaseAPI from "./BaseAPI";
import {Mutations} from "@/store/enums/StoreEnum";
import store from "@/store";

export default class AuthAPI extends BaseAPI {
    static login(credentials) {
        return new Promise((resolve, reject) => {
            this.post(`/login`, credentials).then((data) => {
                if (data.success) {
                    store.commit(Mutations.SET_AUTH, data.data);
                    this.setHeader();
                    resolve();
                } else {
                    store.commit(Mutations.SET_ERROR, data.message);
                    reject();
                }
            });
        });
    }

    static logout() {
        return new Promise((resolve, reject) => {
            this.post(`/logout`, {})
                .then(() => {
                    store.commit(Mutations.PURGE_AUTH);
                    resolve();
                })
                .catch(({ response }) => {
                    store.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

}