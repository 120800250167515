const MenuConfig = [
    {
        pages: [
            {
                sectionTitle: "Dashboard",
                route: "/dashboard",
                fontIcon: "fas fa-tachometer-alt-average",
            },
            {
                sectionTitle: "Administrator",
                route: "/administrator/list",
                fontIcon: "fas fa-user",
            },
            {
                sectionTitle: "Member level",
                route: "/member-level/list",
                fontIcon: "fas fa-clipboard-list",
            },
            {
                sectionTitle: "Category",
                route: "/category/list",
                fontIcon: "fas fa-clipboard-list",
            },
            {
                sectionTitle: "Thread",
                route: "/thread/list",
                fontIcon: "fas fa-clipboard-list",
            },
            {
                sectionTitle: "User",
                route: "/user/list",
                fontIcon: "fas fa-clipboard-list",
            },
            {
                sectionTitle: "Thread Report",
                route: "/thread-report/list",
                fontIcon: "fas fa-clipboard-list",
            },
            {
                sectionTitle: "Comment Report",
                route: "/thread-comment-report/list",
                fontIcon: "fas fa-clipboard-list",
            },
        ]
    },
    // {
    //     heading: "Section 2",
    //     route: "/",
    //     pages: [
    //         {
    //             sectionTitle: "Home",
    //             route: "/home",
    //             fontIcon: "fas fa-tachometer-alt-average",
    //             sub: [
    //                 {
    //                     heading: "Home",
    //                     route: "home",
    //                     name: "home",
    //                 },
    //             ]
    //         },
    //     ]
    // }
];

export default MenuConfig;
