<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">List User</h1>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <section class="content">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <form @submit.prevent="submitForm">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-4">
                  <label class="col-form-label">Search</label>
                  <input class="form-control form-control-sm" v-model="search">
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container-fluid card-body card">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Total record: {{ total }}</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <table id="example1" class="table table-bordered table-striped">
                <thead>
                <tr class="text-center">
                  <th>No</th>
                  <th>Nickname</th>
                  <th>Email</th>
                  <th>Wallet</th>
                  <th>Level</th>
                  <th>Status</th>
                  <th>Register date</th>
                  <th>Total Referral</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr class="text-center" v-for="(user, i) in users" :key="i">
                  <td>{{ (page - 1) * pageSize + i + 1 }}</td>
                  <td>{{ user.nickname }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.walletType + ' - ' + user.walletAddress }}</td>
                  <td>{{ user?.memberLevel?.name || "" }}</td>
                  <td>
                    <span class="badge badge-primary" v-if="user.status == 1">Active</span>
                    <span class="badge badge-warning" v-else-if="user.status == 0">Pending</span>
                    <span class="badge badge-secondary" v-else>DeActive</span>
                  </td>
                  <td>{{ dateFromTimestamp(user.createdAt) }}</td>
                  <td>{{ user.invitation }}</td>
                  <td>
                    <router-link :to="{
                      name: 'user.edit',
                      params: {id: user.id}
                    }">
                      <a title="Edit" class="btn btn-default btn-xs mr-2"><i class="fa fa-edit"></i></a>
                    </router-link>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                </tfoot>
              </table>
              <div class="text-right">
                <pagination class="text-center" v-model="page" :records="total" :per-page="pageSize" @paginate="changePage"/>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </section>
</template>

<script>
import { onMounted, ref } from "vue";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnum";
import {Status} from "../../enums/BaseEnum";
import {useRoute, useRouter} from "vue-router";
import Pagination from 'v-pagination-3';
import {dateFromTimestamp} from "../../helpers/date";

export default {
  name: "ListUser",
  methods: {dateFromTimestamp},
  components: {
    Pagination
  },
  computed: {
    Status() {
      return Status
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const search = ref(route.query.search || "");
    const page = ref(parseInt(route.query.page || "1"));
    const pageSize = ref(parseInt(route.query.page_size || "10"));
    const total = ref(0);
    const users = ref([]);
    onMounted(() => {
      getListUser();
    });

    const getListUser = async () => {
      const params = {
        search: search.value,
        page: page.value,
        pageSize: pageSize.value
      };

      store.dispatch(Actions.GET_LIST_USER, params)
          .then((res) => {
            users.value = res.users;
            total.value = res.total;
          }).catch((err) => {
        console.log('error', err);
      })
    }

    const submitForm = () => {
      page.value = 1;
      getListUser();
      updateRoute();
    }

    const changePage = (val) => {
      page.value = val;
      updateRoute();
      getListUser();
    }
    const updateRoute = (isClear = false) => {
      const opts = {
        path: route.path,
      };
      if (!isClear) {
        opts["query"] = {
          search: search.value,
          page: page.value,
          page_size: pageSize.value,
        };
      }
      router.push(opts);
    };

    return {
      getListUser,
      users,
      search,
      page,
      pageSize,
      total,
      submitForm,
      updateRoute,
      changePage,
    }
  }
};
</script>

<style>
</style>
