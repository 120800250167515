<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Edit Category</h1>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card card-primary">
            <Form
                @submit="updateCategory"
                :validation-schema="schema"
                v-slot="{ errors }"
            >
              <FormCategory
                  v-if="category.id && memberLevels.length"
                  :category="category"
                  :errors="errors"
                  :member-levels="memberLevels"
                  @add-status="addStatus"
                  @remove-status="removeStatus"
                  @add-stage="addStage"
                  @remove-stage="removeStage"
                  @add-status-badge="addStatusBadge"
                  @remove-status-badge="removeStatusBadge"
              >
              </FormCategory>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </Form>
          </div>
        </div>

      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
</template>

<script>
import {onMounted, ref} from "vue";
import {Form} from "vee-validate";
import * as Yup from "yup";
import {Actions} from "@/store/enums/StoreEnum";
import Swal from "sweetalert2";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import FormCategory from "@/pages/category/FormCategory.vue";

export default {
  name: "EditCategory",
  components: {
    FormCategory,
    Form
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const baseStatusBadge = ref({
      name: "",
      nameEn: "",
      code: "",
    })
    const baseStage = ref({
      name: "",
      nameEn: "",
      level: 0,
      forumLink: 0,
      snapshotId: 0,
    })
    const baseStatus = ref({
      name: "",
      nameEn: "",
      level: 0,
    })
    const category = ref({
      memberLevelIds: [],
      orderNo: 0,
      statuses: [baseStatus.value],
      stages: [baseStage.value],
      statusBadges: [baseStatusBadge.value],
    });

    const addStatus = () => {
      category.value.statuses?.push({...baseStatus.value})
    }

    const removeStatus = (index) => {
      category.value.statuses?.splice(index, 1);
    }

    const addStage = () => {
      category.value.stages?.push({...baseStage.value})
    }

    const removeStage = (index) => {
      category.value.stages?.splice(index, 1);
    }

    const addStatusBadge = () => {
      console.log('add status badge');
      category.value.statusBadges?.push({...baseStatusBadge.value})
    }

    const removeStatusBadge = (index) => {
      category.value.statusBadges?.splice(index, 1);
    }

    const memberLevels = ref([]);
    onMounted(async () => {
      await store.dispatch(Actions.GET_LIST_MEMBER_LEVEL, {
        page: 1,
        pageSize: 1000,
      }).then((res) => {
        memberLevels.value = res.memberLevels;
      }).catch((err) => {
        console.log('error', err);
      })
      getDetail();
    });

    const schema = Yup.object().shape({
      name: Yup.string().required(),
    });

    const updateCategory = () => {
      store.dispatch(Actions.UPDATE_CATEGORY, {
        id: route.params.id,
        data: category.value
      }).then(() => {
        Swal.fire({
          text: "Update Success",
          icon: "success",
          buttonsStyling: true,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        });
        router.push({ name: "category.index" });
      })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors,
              icon: "error",
              buttonsStyling: true,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    const initCategoryData = () => {
      if (!category.value.statuses) {
        category.value.statuses = [baseStatus.value];
      }
      if (!category.value.stages) {
        category.value.stages = [baseStage.value];
      }
      if (!category.value.statusBadges) {
        category.value.statusBadges = [baseStatusBadge.value];
      }
    };

    const getDetail = () => {
      store.dispatch(Actions.DETAIL_CATEGORY, route.params.id)
          .then((data) => {
            category.value = data.category;
            initCategoryData();
          });
    }

    return {
      memberLevels,
      category,
      addStatus,
      removeStatus,
      addStage,
      removeStage,
      addStatusBadge,
      removeStatusBadge,
      updateCategory,
      schema,
      getDetail
    }
  }
};
</script>

<style>
</style>
