<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Edit Thread: {{ thread?.title || "" }}</h1>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <section class="content">
    <div class="container-fluid">
      <div class="row mb-5">
        <div class="col-12">
          <Form
            @submit="updateThread"
            v-slot="{ errors }"
          >
            <FormThread
              :thread="thread"
              :errors="errors"
              @update-forum-links="handleForumLinks"
            >
            </FormThread>
            <div class="">
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </Form>
        </div>
      </div>
      <thread-history :histories="thread.histories"></thread-history>
    </div>
    <!-- /.container-fluid -->
  </section>
</template>

<script>
import {onMounted, ref} from "vue";
import {Form} from "vee-validate";
import {Actions} from "@/store/enums/StoreEnum";
import Swal from "sweetalert2";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import FormThread from "@/pages/thread/FormThread.vue";
import ThreadHistory from "@/pages/thread/ThreadHistory";

export default {
  name: "EditThread",
  components: {
    ThreadHistory,
    FormThread,
    Form
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const thread = ref({
      isHide: 0,
      statusBadgeCode: "",
      statusLevel: "",
      stageLevel: "",
      snapshotId: "",
      votes: [],
      forumLink: "",
      histories: [],
    });

    onMounted(async () => {
      getDetail();
    });

    const handleForumLinks = (data) => {
      thread.value.forumLink = data;
    }

    const updateThread = async () => {
      await store.dispatch(Actions.UPDATE_THREAD, {
        id: route.params.id,
        data: {
          isHide: thread.value.isHide,
          statusBadgeCode: thread.value.statusBadgeCode,
          statusLevel: thread.value.statusLevel,
          stageLevel: thread.value.stageLevel,
          snapshotId: thread.value.snapshotId,
          forumLink: thread.value.forumLink,
        }
      }).then(() => {
        Swal.fire({
          text: "Update Success",
          icon: "success",
          buttonsStyling: true,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        });
        router.push({ name: "thread.index" });
      })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors,
              icon: "error",
              buttonsStyling: true,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    const initSnapshotData = () => {
      thread.value?.votes?.forEach((vote) => {
        thread.value.snapshotId = vote['snapshotId'];
      })
    }

    const initForumLink = () => {
      const history = thread.value?.histories?.find((history) => history['step'].toString() === thread.value.stageLevel.toString());
      if (history) {
        thread.value.forumLink = history['forumUrl'];
      }
    }

    const getDetail = () => {
      store.dispatch(Actions.DETAIL_THREAD, route.params.id)
        .then((data) => {
          thread.value = data.thread;
          initSnapshotData();
          initForumLink();
        });
    }

    return {
      handleForumLinks,
      thread,
      updateThread,
      getDetail
    }
  }
};
</script>

<style>
</style>
