import BaseAPI from "./BaseAPI";

import {Mutations} from "@/store/enums/StoreEnum";
import store from "@/store";

export default class DefaultApi extends BaseAPI {
    static url;
    static list(params) {
        return new Promise((resolve, reject) => {
            this.get(`${this.url}`, params).then((data) => {
                if (data.success) {
                    resolve(data.data);
                } else {
                    store.commit(Mutations.SET_ERROR, data.message);
                    reject();
                }
            });
        });
    }

    static create(params) {
        return new Promise((resolve, reject) => {
            this.post(`${this.url}`, params).then((data) => {
                if (data.success) {
                    resolve(data.data);
                } else {
                    store.commit(Mutations.SET_ERROR, data.message);
                    reject();
                }
            });
        });
    }

    static detail(id) {
        return new Promise((resolve, reject) => {
            this.get(`${this.url}/${id}`, {}).then((data) => {
                if (data.success) {
                    resolve(data.data);
                } else {
                    store.commit(Mutations.SET_ERROR, data.message);
                    reject();
                }
            });
        });
    }

    static update(id, params) {
        return new Promise((resolve, reject) => {
            this.patch(`${this.url}/${id}`, params).then((data) => {
                if (data.success) {
                    resolve(data.data);
                } else {
                    store.commit(Mutations.SET_ERROR, data.message);
                    reject();
                }
            });
        });
    }

    static destroy(id) {
        return new Promise((resolve, reject) => {
            this.delete(`${this.url}/${id}`, {}).then((data) => {
                if (data.success) {
                    resolve(data.data);
                } else {
                    store.commit(Mutations.SET_ERROR, data.message);
                    reject();
                }
            });
        });
    }
}