<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">List Member Level</h1>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <section class="content">
    <div class="container-fluid card-body card">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Total record: {{ total }}</h3>
              <router-link :to="{name : 'member_level.create'}" class="float-right">
                <button type="button" class="btn btn-primary"><i class="fa fa-plus"></i> Create</button>
              </router-link>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <table id="example1" class="table table-bordered table-striped">
                <thead>
                <tr class="text-center">
                  <th>No</th>
                  <th>Name</th>
                  <th>MinStKlay</th>
                  <th>Created date</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr class="text-center" v-for="(memberLevel, i) in memberLevels" :key="i">
                  <td>{{ (page - 1) * pageSize + i + 1 }}</td>
                  <td>{{ memberLevel.name + ' - ' + memberLevel.nameEn }}</td>
                  <td>{{ memberLevel.minStKlay }}</td>
                  <td>{{ dateFromTimestamp(memberLevel.createdAt) }}</td>
                  <td>
                    <router-link :to="{
                      name: 'member_level.edit',
                      params: {id: memberLevel.id}
                    }">
                      <a title="Edit" class="btn btn-default btn-xs mr-2"><i class="fa fa-edit"></i></a>
                    </router-link>
                    <a title="Delete" @click="actionDelete(memberLevel.id)" class="btn btn-danger btn-xs mr-2">
                      <i class="fa fa-trash"></i>
                    </a>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                </tfoot>
              </table>
              <div class="text-right">
                <pagination class="text-center" v-model="page" :records="total" :per-page="pageSize" @paginate="changePage"/>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </section>
</template>

<script>
import {defineComponent, onMounted, ref } from "vue";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnum";
import {Status} from "@/enums/BaseEnum";
import {useRoute, useRouter} from "vue-router";
import Pagination from 'v-pagination-3';
import Swal from "sweetalert2";
import {dateFromTimestamp} from "@/helpers/date";

export default defineComponent({
  name: "ListMemberLevel",
  methods: {dateFromTimestamp},
  components: {
    Pagination
  },
  computed: {
    Status() {
      return Status
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const name = ref(route.query.name || "");
    const page = ref(parseInt(route.query.page || "1"));
    const pageSize = ref(parseInt(route.query.page_size || "10"));
    const total = ref(0);
    const memberLevels = ref([]);
    onMounted(() => {
      getListMemberLevel();
    });

    const getListMemberLevel = async () => {
      const params = {
        name: name.value,
        page: page.value,
        pageSize: pageSize.value
      };

      store.dispatch(Actions.GET_LIST_MEMBER_LEVEL, params)
          .then((res) => {
            memberLevels.value = res.memberLevels;
            total.value = res.total;
          }).catch((err) => {
        console.log('error', err);
      })
    }

    const submitForm = () => {
      page.value = 1;
      getListMemberLevel();
      updateRoute();
    }

    const changePage = (val) => {
      page.value = val;
      updateRoute();
      getListMemberLevel();
    }
    const updateRoute = (isClear = false) => {
      const opts = {
        path: route.path,
      };
      if (!isClear) {
        opts["query"] = {
          name: name.value,
          page: page.value,
          page_size: pageSize.value,
        };
      }
      router.push(opts);
    };

    const actionDelete = (id) => {
      Swal.fire({
        title: 'Do you want to delete?',
        showCancelButton: true,
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(Actions.DELETE_MEMBER_LEVEL, id)
              .then(() => {
                Swal.fire('Deleted!', '', 'success')
                getListMemberLevel();
              })
              .catch(() => {
                Swal.fire('Error!', '', 'error')
              });
        }
      })
    }

    return {
      getListMemberLevel,
      memberLevels,
      name,
      page,
      pageSize,
      total,
      submitForm,
      updateRoute,
      changePage,
      actionDelete,
    }
  }
});
</script>

<style>
</style>