import {Action, Module, VuexModule} from "vuex-module-decorators";
import ThreadApi from "@/api/ThreadApi";
import ThreadReportApi from "@/api/ThreadReportApi";
import ThreadCommentReportApi from "@/api/ThreadCommentReportApi";

@Module
class ThreadModule extends VuexModule {
    @Action
    getListThread(params) {
        return ThreadApi.list(params);
    }

    @Action
    detailThread(payload) {
        return ThreadApi.detail(payload);
    }

    @Action
    updateThread(payload) {
        const id = payload.id;
        const params = payload.data;
        return ThreadApi.update(id, params);
    }

    @Action
    deleteThread(id) {
        return ThreadApi.destroy(id);
    }

    @Action
    getListThreadReport(params) {
        return ThreadReportApi.list(params);
    }

    @Action
    detailThreadReport(params) {
        return ThreadReportApi.detail(params);
    }

    @Action
    getListCommentReport(params) {
        return ThreadCommentReportApi.list(params);
    }

    @Action
    detailCommentReport(params) {
        return ThreadCommentReportApi.detail(params);
    }

    @Action
    toggleComment(id) {
        return ThreadCommentReportApi.toggleComment(id);
    }
}

export { ThreadModule }
